.login {
  background: var(--ion-background-color);
}

.login-logo-container {
  display: flex;
  justify-content: center;
}

.login-logo {
  max-width: 200px;
  margin: 100px 50px 30px 50px;
}

.login ion-input {
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.reset-password {
  margin-bottom: 1.875em
}