.email-notifications-list ion-item ion-label {
  margin-left: 1.4rem;
  font-size: 1rem !important;
}

.email-notifications-list ion-item ion-toggle {
  zoom: 0.8;
  margin-right: 6px;
}

.add-new-sub-user ion-icon {
  font-size: 24px;
}

.edit-user-profile ion-icon {
  color: white;
}

.install-pwa {
  margin-bottom: 16px;
}

#profile-options-fab {
  margin-bottom: 10px;
}

.icon-label {
  position: absolute;
  top: 48px;
  font-size: 14px;
  margin-top: 4px
}

.icon-label:nth-of-type(1) {
  right: 10px;
}

.icon-label:nth-of-type(2) {
  right: 56px;
}

.icon-label:nth-of-type(3) {
  right: 104px;
}
