.sub-users-list .sub-user-card {
  display: flex;
  box-sizing: border-box;
  margin: 16px;
  margin-top: 14px;
  border-radius: 6px;
  background: #fafafa;
  box-shadow: 0px 2px 4px #cbced1;
}

.sub-users-list .sub-user-card ion-item::part(native) {
  background: none;
}

.sub-users-list .sub-user-card .sub-user-info {
  width: 100%;
}

.impersonate-sub-user-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  background: var(--ion-color-primary-opacity);
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.impersonate-sub-user-button .impersonate-user {
  font-size: 2.2rem;
  fill: var(--ion-color-primary-shade);
}

/* Dark mode */
body.dark .sub-user-card {
  background-color: var(--ion-item-background);
  box-shadow: 0px 0px 8px #444242;
}
