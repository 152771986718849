input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

div.expiration-warning,
div.expiration-default-warning {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  align-items: center;
  color: #fff;
}

div.expiration-warning p,
div.expiration-default-warning p {
  max-width: 80% !important;
  border-radius: 20px;
  padding: 8px;
  box-shadow: 0 0 0 0.5px rgb(0 0 0 / 20%);
  background-color: var(--ion-color-primary);
  color: #fff;
  text-align: center;
  font-weight: 600;
  white-space: normal !important;
}
