.input-password {
  display: flex;
  width: 100%;
  align-items: center;
}

.password-toggle {
  z-index: 99999;
  position: absolute;
  right: 26px;
  margin-bottom: 10px;
  color: var(--ion-color-primary);
  background: transparent;
}

.password-toggle ion-icon {
  font-size: 1.6rem;
  display: block;
}
