ion-toolbar:not(.ios) .notifications-item-icon-container {
  margin: auto;
  margin-right: 0;
}

ion-toolbar.ios .notifications-item-icon-container {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}

.notifications-item-icon-container {
  margin-right: 0;
}

.notifications-item-icon-container ion-icon.notifications-item-icon {
  font-size: 26px;
  margin-right: 10px;
  cursor: pointer;
}

ion-popover#notifications-popover::part(content) {
  right: 10px;
  left: auto !important;
}

ion-item.notification-item::part(native) {
  --padding-start: 0px;
  max-height: 60px;
  min-height: 60px;
}

ion-label.notification-info {
  display: flex;
  flex-direction: column;
  max-height: 60px;
}

ion-list#notifications-list .notification-item .notification-container {
  display: flex;
  align-items: center;
}

ion-list#notifications-list .notification-item ion-icon {
  margin-right: 6px;
  font-size: 30px !important;
  min-width: 30px !important;
  color: var(--ion-color-primary);
}

.notification-title {
  font-size: 16px;
  font-weight: bold;
}

.notification-body {
  font-size: 14px;
  max-height: 20px;
}
