.no-payments-text {
  margin: 0 10%;
  white-space: pre-line;
}

.payment-status-filters {
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: white;
  padding: 8px 0;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

ion-chip.payment-name {
  display: block;
  width: auto;
  margin-inline: 0;
  text-overflow: ellipsis;
  max-width: fit-content;
}

.payments-list-hide {
  animation: fadeOut 1s forwards;
}

.payments-list-show {
  animation: fadeIn 1s forwards;
}

/* Dark mode */
body.dark .payment-status-filters {
  background-color: var(--ion-background-color);
}
