ion-modal.send-whatsapp-modal::part(content) {
  width: 80%;
  border-radius: 0.875em;
  border: 2px solid var(--ion-color-primary);
  min-height: 22em;
  height: 0;
}

ion-modal.send-whatsapp-modal ion-content::part(scroll) {
  overflow: hidden !important;
}

ion-modal.send-whatsapp-modal .modal-content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

ion-modal.send-whatsapp-modal .modal-content-container .buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 1em;
}
