ion-grid.statistics-chart-bar-container {
  font-size: small;
}

ion-grid.statistics-chart-bar-container ion-row {
  text-align: start;
}

ion-grid.statistics-chart-bar-container div.statistics-chart-bar-row {
  margin: 10px 0;
}

ion-grid.statistics-chart-bar-container div.statistics-chart-bar-row ion-row {
  text-align: end;
  align-items: center;
}

ion-grid.statistics-chart-bar-container div.statistics-chart-bar-row:last-child {
  margin: 0;
}

ion-col.statistics-chart-title {
  text-align: start;
}

ion-col.statistics-chart-title .statistics-chart-title-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: var(--statistic-bar-chart-color);
  margin-right: 5px;
}

ion-col.statistics-chart-bar {
  width: 100%;
  height: 10px;
  background-color: #d4d4d4;
  border-radius: 10px;
  overflow: hidden;
  padding: 0;
}

ion-col.statistics-chart-bar .statistics-bar {
  height: 10px;
  border-radius: 10px;
}

ion-col.statistics-chart-bar .statistics-bar.statistics-fill-bar-open {
  animation: fill 0.6s cubic-bezier(0.77, 0.27, 0.44, 1.27) forwards;
}

ion-col.statistics-chart-bar .statistics-bar.statistics-fill-bar-close {
  animation: unfill 0.3s cubic-bezier(0.62, -0.24, 0.16, 0.71) forwards;
}

.statistics-total-amount {
  font-weight: bold;
}
