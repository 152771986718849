ion-modal.send-to-favorite-user-modal::part(content) {
  width: 80%;
  border-radius: 0.875em;
  min-height: 22em;
  height: 0;
}

ion-modal.send-to-favorite-user-modal .modal-content-container ion-list {
  margin-bottom: 3.125rem;
}

ion-modal.send-to-favorite-user-modal .modal-content-container ion-list ion-radio-group ion-item ion-thumbnail {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  height: auto;
  margin-right: 1.25rem;
}

ion-modal.send-to-favorite-user-modal .modal-content-container ion-list ion-radio-group ion-item ion-label p {
  font-size: 0.75rem;
}

ion-modal.send-to-favorite-user-modal .modal-content-container .buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  background: #fff;
  padding: 0.875rem 0;
}

ion-modal.send-to-favorite-user-modal .modal-content-container .buttons-container ion-button {
  height: 2em;
}

.logo-favorite-user-modal {
  max-width: 140px;
  margin: 20px 25px 15px 25px;
}

.no-favorite-users-text {
  margin: 0 10%;
  white-space: pre-line;
}
