ion-modal.send-whatsapp-modal {
  background-color: rgba(0, 0, 0, 28%);
}

ion-modal.send-whatsapp-modal ion-content {
  --keyboard-offset: 0 !important;
}

ion-modal#share-buttons-modal::part(content) {
  max-width: 74%;
  min-height: 29rem;
  border-radius: 0.875em;
  border: 2px solid var(--ion-color-primary);
  height: 0;
}

ion-modal#share-buttons-modal ion-content ion-toolbar {
  position: sticky;
  top: 0;
}

ion-grid#share-buttons-grid ion-row {
  margin: 0.625rem;
}

ion-grid#share-buttons-grid ion-row ion-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

ion-grid#share-buttons-grid ion-row ion-col ion-text {
  margin: 1rem 0.625rem 0 0.625rem;
}
