.cookies-modal-container {
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  background-color: rgb(84 110 122 / 0.5);
}

.cookies-modal-container ion-card {
  padding: 10px;
  margin-top: 20%;
  max-height: 90%;
  overflow-y: auto;
}

.cookies-management {
  padding: 0 !important;
  margin: 0 !important;
  margin-inline: 0 !important;
  border-radius: 0 !important;
  height: 100% !important;
}

.cookies-management ion-card {
  padding: 0 !important;
  margin: 0 !important;
  margin-inline: 0 !important;
  border-radius: 0 !important;
  height: 100% !important;
  min-height: 100% !important;
}

.cm-title {
  font-size: 20px;
}

.cm-body {
  overflow-y: auto;
}

a:hover {
  text-decoration: none;
}

.cookies-modal-footer {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

button#denied-all,
button#cookie-config,
button#accept-all,
button#permit-all,
button.cookie-list {
  border: 1px solid var(--ion-color-primary);
  padding: 14px;
  font-weight: bold;
}

button#denied-all:hover,
button#cookie-config:hover,
button#accept-all:hover,
button#permit-all:hover,
button.cookie-list:hover,
ion-button.go-back:hover {
  opacity: 0.6;
}

button#denied-all,
button#cookie-config,
button.cookie-list {
  background-color: white;
  color: var(--ion-color-primary);
}

button#accept-all,
button#permit-all {
  background-color: var(--ion-color-primary);
  color: white;
}

button#permit-all {
  margin: 0 20px;
}

button.cookie-list {
  width: 100%;
  /* margin: 10px 20px; */
}

ion-button.go-back {
  color: var(--ion-color-primary);
}

ion-button.go-back span {
  margin-left: 6px;
}

#cm-header {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
}

.header-logo {
  width: 120px;
}

.cm-divider {
  position: absolute;
  left: 0;
  width: 100%;
  height: 0;
  min-height: 0;
  max-height: 0;
}

#cm-body-title {
  color: #4e4e4e;
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  font-weight: bold;
}

.technical_cookies {
  border: 1px solid #afafaf;
  border-radius: 4px;
  background-color: #f8f8f8;
}

.technical_cookies h2 {
  color: #4e4e4e;
  font-weight: bold;
  line-height: 1.5;
  background-color: white;
}

.technical_cookies h2,
.technical_cookies p,
.technical_cookies div {
  padding: 8px;
}
