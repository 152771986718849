.share-list {
  margin-inline: 58px;
}

.share-list a {
  margin-right: 6px;
}

.share-list ion-fab-button {
  width: 44px;
  height: 44px;
}

.share-list ion-fab-button ion-icon {
  font-size: 16px;
}

.payment-without-url {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  align-items: center;
}

.payment-without-url p {
  width: 80%;
  border-radius: 20px;
  padding: 8px;
  box-shadow: 0 0 0 0.5px rgb(0 0 0 / 20%);
  background-color: var(--ion-color-primary);
  color: #fff;
  text-align: center;
  font-weight: 600;
}

.favorite-users-button {
  margin-bottom: 4.4rem;
}

.multiple-payment-client-info {
  margin-top: 1rem;
}

.multiple-payment-client-info ion-item:first-child {
  font-weight: bold;
}
