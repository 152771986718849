
html,
body {
  height: 100%;
  max-width: 500px;
}

html {
  display: table;
  margin: auto;
}

body {
  display: table-cell;
  vertical-align: middle;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

ion-button {
  --border-radius: 0;
}

ion-item.list-item ion-thumbnail {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

ion-item.list-item ion-thumbnail,
ion-item.list-item ion-label {
  margin-top: 0;
  margin-bottom: 0;
}

ion-item.list-item ion-icon.item-icon {
  font-size: 40px;
}

form {
  margin-bottom: 0.875em !important;
}

form:not(#search-filters-form) ion-label {
  margin-bottom: 0.375em !important;
}

ion-fab-button.icon-danger::part(native) {
  background-color: var(--ion-color-danger-shade);
}

ion-fab-button.icon-success::part(native) {
  background-color: var(--ion-color-success-shade);
}

ion-fab-button.icon-danger ion-icon,
ion-fab-button.icon-success ion-icon {
  color: var(--ion-color-light);
}

.text-center {
  text-align: center;
}

.hidden {
  display: none;
}

.not-visible {
  visibility: hidden;
}

ion-searchbar.ios {
  padding-top: 15px;
}

ion-popover::part(content) {
  left: 14px !important;
  width: 70%;
}

.no-ripple {
  --ripple-color: transparent;
}

.no-padding-top {
  padding-top: 0;
}

.item-disabled {
  pointer-events: unset !important;
  opacity: 1 !important;
}

.item-disabled * {
  color: var(--ion-color-medium-tint) !important;
}

.d-none {
  display: none;
}

.no-scroll,
.no-scroll::part(scroll) {
  overflow: hidden;
}

.bounce-hide {
  animation: bounceOutDown 0.8s forwards;
}

.bounce-show {
  animation: bounceInUp 0.8s forwards;
}

.reset-button {
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.qr-code-container {
  margin: 1.5em 0;
  display: flex;
  justify-content: center;
}

.qr-code-container div {
  border: 2px solid var(--ion-color-primary);
  border-radius: 20px;
  padding: 14px;
}

.mapped-item .mapped-item-inner {
  width: 98%;
  --padding-start: 0px;
  --inner-padding-end: 0px;
}

.mapped-item > ion-icon {
  margin-left: 0.25rem;
  font-size: 1.75rem;
  cursor: pointer;
}

.list-item-icon-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list-item-icon-button .list-item-icon {
  font-size: 1.75rem;
}

.list-item-icon-button ion-label {
  color: var(--ion-color-primary) !important;
  font-size: 0.8rem !important;
  font-weight: bold;
  margin-top: 0.125rem;
}

.item-chips {
  display: flex;
  flex-direction: column;
}

.item-chips ion-chip {
  justify-content: center;
}

.toolbar-container {
  display: flex;
}

.w-full {
  width: 100%;
}

.notifications-count {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  top: 4px;
  right: 6px;
  border-radius: 50px;
  color: #fff;
  background-color: #f05050;
  text-align: center;
  vertical-align: middle;
  font-size: 10px;
  font-weight: bold;
}

.notifications-count.tab-router {
  top: 2px;
  right: 16px;
}

ion-toolbar:not(.ios) .notifications-count,
ion-tab-button:not(.ios) .notifications-count.tab-router {
  right: 0;
}

.flex-space-between-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.flex-space-between-container > div {
  width: 100%;
}
