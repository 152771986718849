.impersonate-user-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.impersonate-user-button .impersonate-user {
  font-size: 2.2rem;
  fill: var(--ion-color-primary-shade);
}
