#search-filters-container ion-row {
  align-items: center;
}

#search-filters-container ion-input[name=search] {
  background: var(--ion-color-primary-opacity2);
  padding: 10px !important;
  border-radius: 12px;
}

#search-filters-container .date-filter ion-row.date-inputs {
  display: flow-root;
}

#search-filters-container .date-filter ion-row:not(.date-inputs) ion-col {
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#search-filters-container .date-filter ion-col ion-item ion-text {
  display: flex;
  align-items: center;
  font-size: 17px;
}

#search-filters-container .date-filter ion-col ion-item ion-text:nth-child(2) {
  position: absolute;
  right: 14px;
}

#search-filters-container .date-filter ion-col ion-item ion-text:nth-child(2),
#search-filters-container .date-filter ion-grid ion-row ion-col ion-label:nth-child(2) {
  background: var(--ion-color-primary-opacity2);
  padding: 6px !important;
  border-radius: 12px;
}

#search-filters-container .date-filter ion-grid ion-row ion-col ion-label:nth-child(2),
#search-filters-container .date-filter ion-col ion-item ion-text ion-label {
  font-size: 14px;
}

#search-filters-container ion-accordion-group .date-filter ion-grid ion-accordion {
  background-color: transparent;
}

#search-filters-container .date-filter ion-col ion-item ion-text ion-icon,
#search-filters-container ion-accordion-group .date-filter ion-grid ion-accordion ion-row ion-col ion-label ion-icon {
  font-size: 20px;
  margin: 0 10px;
}

#search-filters-container ion-accordion-group .date-filter ion-grid ion-accordion ion-row ion-col ion-label {
  display: flex;
  align-items: center;
}

ion-toolbar:not(.ios) .statistics-item-icon-container,
ion-toolbar:not(.ios) .search-item-icon-container {
  margin: auto;
  margin-right: 0;
}

ion-toolbar.ios .search-item-icon-container {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}

.statistics-item-icon-container {
  margin-right: 10px;
}

.search-item-icon-container {
  margin-right: 0;
}

.statistics-item-icon-container ion-icon.statistics-item-icon,
.search-item-icon-container ion-icon.search-item-icon {
  font-size: 26px;
  margin-right: 10px;
  cursor: pointer;
}

#search-filters-container ion-backdrop {
  position: fixed;
}

#search-filters {
  position: absolute;
  border-radius: 0 0 20px 20px;
  z-index: 3;
}

#search-filters ion-label {
  margin: 0;
}

#search-filters ion-input input {
  padding: 0;
}

#search-filters ion-list,
#search-filters ion-item::part(native) {
  background-color: var(--ion-color-primary);
  color: white;
}

.save-filters {
  display: flex;
  width: 80%;
  color: white;
  margin: 16px auto;
  text-transform: uppercase;
}

.save-filters::part(native),
.reset-filters::part(native) {
  border-radius: 20px;
  font-weight: 600;
  box-shadow: rgb(0 0 0 / 2%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px;
}
.save-filters::part(native) {
  background: var(--ion-color-tertiary);
}

.reset-filters-container {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  margin: 16px auto;
}

.reset-filters {
  color: white;
}

.reset-filters::part(native) {
  background: var(--ion-color-danger-shade);
}

ion-select[name="store"],
ion-select[name="subUser"] {
  max-width: unset;
}
