.statistics-page {
  z-index: 99999;
  position: fixed;
  top: 44px;
  /* height: -webkit-fill-available; */
  /* width: -webkit-fill-available; */
  transform: translateX(100%);
}

.statistics-open {
  animation: slideIn 0.6s ease-in forwards;
  overflow: scroll;
  box-shadow: 0px 0 12px -4px #bababa;
}

.statistics-close {
  animation: slideOut 0.6s ease-in forwards;
  overflow: scroll;
  box-shadow: 0px 0 12px -4px #bababa;
}

.statistics-card {
  border-radius: 6px;
  margin: 16px;
  padding: 10px;
  box-sizing: border-box;
  background: #fafafa;
  box-shadow: 0px 2px 8px #cbced1;
}

.statistics-card:last-child {
  margin-bottom: 3.75rem;
}

.statistics-card ion-item::part(native) {
  background-color: transparent;
}

.send-excel-button {
  position: fixed;
  z-index: 99999;
  transform: translate3d(0, 2000px, 0) scaleY(3);
}

.statistics-card ion-icon {
  min-width: 1.7rem;
  min-height: 1.7rem;
}

/* Dark mode */
body.dark .statistics-card {
  background-color: var(--ion-tab-bar-background);
  box-shadow: 0px 0px 8px #635f5f;
}
