.generate-payment-page {
  text-align: center;
}

.generate-payment-title {
  color: var(--ion-color-primary);
  font-weight: bold;
}

.generate-payment-container {
  border-radius: 20px;
  margin-top: 20px;
  padding: 30px;
  box-sizing: border-box;
  background: #fafafa;
  box-shadow: 0px 2px 8px #cbced1, -14px -14px 20px white;
}

.generate-payment-container ion-list {
  background-color: #fafafa;
}

.generate-payment-container ion-list ion-item::part(native) {
  background-color: #f6f6f6;
}

.required {
  color: red;
  font-weight: bold;
}
