ion-modal.disable-admin-modal::part(content) {
  width: 80%;
  border-radius: 0.875em;
  min-height: 12em;
  height: 0;
}

ion-modal.disable-admin-modal ion-content::part(scroll) {
  overflow: hidden !important;
}

ion-modal.disable-admin-modal .modal-content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

ion-modal.disable-admin-modal .modal-content-container .buttons-container {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}
