.recaptcha-message {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #4b5563;
}

.recaptcha-message-link {
  color: #2563eb;
}
